export const tab_en = {
  "THead": "THead",
  "mqs_ship_manifest_tab0": "mqs_ship_manifest_tab0",
  "mqs_ship_manifest_tab1": "mqs_ship_manifest_tab1",
  "mqs_ship_manifest_tab6": "Shipment Details",
  "mqs_ship_manifest_tab5": "mqs_ship_manifest_tab5",
  "mqs_ship_manifest_tab4": "mqs_ship_manifest_tab4",
  "mqs_ship_manifest_tab30": "mqs_ship_manifest_tab30"
};

export const tab_zh = {
  "THead": "表头",
  "mqs_ship_manifest_tab0": "综合1",
  "mqs_ship_manifest_tab1": "综合2",
  "mqs_ship_manifest_tab6": "Shipment Details",
  "mqs_ship_manifest_tab5": "货箱明细",
  "mqs_ship_manifest_tab4": "托盘明细",
  "mqs_ship_manifest_tab30":"用户自定义的_设置1"
}