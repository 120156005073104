import Api from '@api';

import {YN, PAGER, CONSIGNMENT_STATUS} from '@constant';
import {
  setSelectClientList,
  setSelectOriginList,
  setSelectOwnerList,
  setSelectPalletList,
  setSelectSiteList
} from "../../../../../../api/common";
import {SHIPMENT_LOCK_STATUS, SHIPMENT_STATUS} from "../../../../../../constant";

const defaultPaginationInfo = {
  PAGE_NUM: PAGER.MIN_PAGE_NUM,
  PAGE_SIZE: PAGER.MAX_PAGE_SIZE
}

/**
 * @description 列表和筛选表单不会展示的组
 */
export const noFilterGroup = ['DemandOrderPrefix', 'OrderPrefix', 'PreadvicePrefix', 'UPIPrefix'];

export const getFormConfig = (vm) => {
  return {
    filterFormConfig: [{
      "key": "ORDER_ID",
      "label": "订单号",
      "type": "magnifier",
      "tableName": 'SHIPMENT_MANIFEST',
      "placeholder": "请输入订单号",
      "tabGroup": "mqs_ship_manifest_tab0",
      "tabGroupName": "综合1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "OWNER_ID",
      "label": "所有者",
      "type": "magnifier",
      "placeholder": "请选择所有者",
      "tabGroup": "mqs_ship_manifest_tab0",
      "tabGroupName": "综合1",
      "showBlock": ["all"],
      "checked": false,
      // "options": async () => {
      //   let {status, data: {list}, message} = await setSelectOwnerList({
      //     WORKSTATION: {},
      //     ...defaultPaginationInfo
      //   });
      //
      //   if (Number(status) === 200) {
      //     return list.map(item=>{
      //       return{
      //         key:item.OWNER_ID,
      //         label:item.OWNER_ID
      //       }
      //     })
      //   }
      // },
    }, {
      "key": "SITE_ID",
      "label": "站点",
      "type": "select",
      "placeholder": "请选择站点",
      "tabGroup": "mqs_ship_manifest_tab0",
      "tabGroupName": "综合1",
      "showBlock": ["all"],
      "checked": false,
      "options": async () => {
        let {status, data: {list}, message} = await setSelectSiteList({
          SITE: {},
          ...defaultPaginationInfo
        });

        if (Number(status) === 200) {
          return list.map(item => {
            return {
              key: item.SITE_ID,
              label: item.SITE_ID
            }
          })
        }
      },
    }, {
      "key": "LINE_ID",
      "label": "行号",
      "type": "magnifier",
      "tableName": 'SHIPMENT_MANIFEST',
      "placeholder": "请输入行号",
      "tabGroup": "mqs_ship_manifest_tab0",
      "tabGroupName": "综合1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "TAG_ID",
      "label": "标记ID ",
      "type": "magnifier",
      "tableName": 'SHIPMENT_MANIFEST',
      "placeholder": "请输入标记ID",
      "tabGroup": "mqs_ship_manifest_tab0",
      "tabGroupName": "综合1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "CLIENT_ID",
      "label": "客户端",
      "type": "magnifier",
      "placeholder": "请选择客户端",
      "tabGroup": "mqs_ship_manifest_tab0",
      "tabGroupName": "综合1",
      "showBlock": ["all"],
      defaultUpper:true,
      "checked": false,
    }, {
      "key": "SKU_ID",
      "label": "SKU ",
      "type": "magnifier",
      "placeholder": "请输入SKU ",
      "tabGroup": "mqs_ship_manifest_tab0",
      "tabGroupName": "综合1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "_DESCRIPTION_",
      "label": "描述",
      "type": "textarea",
      "placeholder": "描述",
      "tabGroup": "mqs_ship_manifest_tab0",
      "tabGroupName": "综合1",
      "showBlock": ["dataInfo"],
      "col": 2,
    }, {
      "key": "LOCATION_ID",
      "label": "位置",
      "type": "magnifier",
      "placeholder": "请输入位置",
      "tabGroup": "mqs_ship_manifest_tab0",
      "tabGroupName": "综合1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "CONFIG_ID",
      "label": "打包配置",
      "type": "select",
      "placeholder": "请选择打包配置",
      "tabGroup": "mqs_ship_manifest_tab0",
      "tabGroupName": "综合1",
      "showBlock": ["all"],
      "checked": false,
      "options": []
    }, {
      "key": "USER_ID",
      "label": "用户",
      "type": "magnifier",
      "tableName": 'SHIPMENT_MANIFEST',
      "placeholder": "请输入用户",
      "tabGroup": "mqs_ship_manifest_tab0",
      "tabGroupName": "综合1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "WORK_GROUP",
      "label": "工作组",
      "type": "magnifier",
      "tableName": 'SHIPMENT_MANIFEST',
      "placeholder": "请输入工作组",
      "tabGroup": "mqs_ship_manifest_tab0",
      "tabGroupName": "综合1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "CONSIGNMENT",
      "label": "托运",
      "type": "magnifier",
      "tableName": 'SHIPMENT_MANIFEST',
      "placeholder": "请输入托运",
      "tabGroup": "mqs_ship_manifest_tab0",
      "tabGroupName": "综合1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "SHIPMENT_GROUP",
      "label": "发货组",
      "type": "magnifier",
      "tableName": 'SHIPMENT_MANIFEST',
      "placeholder": "请输入发货组",
      "tabGroup": "mqs_ship_manifest_tab0",
      "tabGroupName": "综合1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "MANUF_DSTAMP",
      "label": "生产日期",
      "type": "date",
      "placeholder": "请选择生产日期",
      "tabGroup": "mqs_ship_manifest_tab0",
      "tabGroupName": "综合1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "MANUF_TIME",
      "label": "生产时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择生产时间",
      "tabGroup": "mqs_ship_manifest_tab0",
      "tabGroupName": "综合1",
      "showBlock": ["dataInfo"],
      "checked": false
    }, {
      "key": "STATION_ID",
      "label": "工作站 ",
      "type": "magnifier",
      "tableName": 'SHIPMENT_MANIFEST',
      "placeholder": "请输入工作站 ",
      "tabGroup": "mqs_ship_manifest_tab0",
      "tabGroupName": "综合1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "CONTAINER_ID",
      "label": "容器ID",
      "type": "magnifier",
      "tableName": 'SHIPMENT_MANIFEST',
      "placeholder": "请输入容器ID",
      "tabGroup": "mqs_ship_manifest_tab0",
      "tabGroupName": "综合1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "PALLET_ID",
      "label": "托盘ID",
      "type": "magnifier",
      "tableName": 'SHIPMENT_MANIFEST',
      "placeholder": "请输入托盘ID",
      "tabGroup": "mqs_ship_manifest_tab0",
      "tabGroupName": "综合1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "SHIPPED",
      "label": "已运送",
      "type": "select",
      "placeholder": "请选择已运送",
      "tabGroup": "mqs_ship_manifest_tab0",
      "tabGroupName": "综合1",
      "showBlock": ["all"],
      "checked": false,
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "TRAILER_POSITION",
      "label": "拖车位置",
      "type": "magnifier",
      "tableName": 'SHIPMENT_MANIFEST',
      "placeholder": "请输入拖车位置",
      "tabGroup": "mqs_ship_manifest_tab0",
      "tabGroupName": "综合1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "LOAD_SEQUENCE",
      "label": "装载顺序",
      "type": "magnifier",
      "tableName": 'SHIPMENT_MANIFEST',
      "placeholder": "请输入装载顺序",
      "tabGroup": "mqs_ship_manifest_tab0",
      "tabGroupName": "综合1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "CATCH_WEIGHT",
      "label": "捕获重量",
      "type": "numrange",
      "placeholder": "请选择捕获重量",
      "tabGroup": "mqs_ship_manifest_tab0",
      "tabGroupName": "综合1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "MASTER_ORDER_ID",
      "label": "主订单号",
      "type": "magnifier",
      "tableName": 'SHIPMENT_MANIFEST',
      "placeholder": "请输入主订单号",
      "tabGroup": "mqs_ship_manifest_tab0",
      "tabGroupName": "综合1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "MASTER_ORDER_LINE_ID",
      "label": "主订单行",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入主订单行",
      "tabGroup": "mqs_ship_manifest_tab0",
      "tabGroupName": "综合1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "QTY_PICKED",
      "label": "拣货数量",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入拣货数量",
      "tabGroup": "mqs_ship_manifest_tab1",
      "tabGroupName": "综合2",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "PICKED_DSTAMP",
      "label": "拣货日期",
      "type": "date",
      "placeholder": "请输入拣货日期",
      "tabGroup": "mqs_ship_manifest_tab1",
      "tabGroupName": "综合2",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "PICKED_TIME",
      "label": "拣货时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请输入拣货时间",
      "tabGroup": "mqs_ship_manifest_tab1",
      "tabGroupName": "综合2",
      "showBlock": ["dataInfo"],
      "checked": false
    }, {
      "key": "QTY_SHIPPED",
      "label": "已运送数",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入已运送数",
      "tabGroup": "mqs_ship_manifest_tab1",
      "tabGroupName": "综合2",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "SHIPPED_DSTAMP",
      "label": "运送日期",
      "type": "date",
      "placeholder": "请选择运送日期",
      "tabGroup": "mqs_ship_manifest_tab1",
      "tabGroupName": "综合2",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "SHIPPED_TIME",
      "label": "运送时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择运送时间",
      "tabGroup": "mqs_ship_manifest_tab1",
      "tabGroupName": "综合2",
      "showBlock": ["dataInfo"],
      "checked": false
    }, {
      "key": "QTY_DELIVERED",
      "label": "交货数量",
      "type": "numrange",
      "placeholder": "请选择交货数量",
      "tabGroup": "mqs_ship_manifest_tab1",
      "tabGroupName": "综合2",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "DELIVERED_DSTAMP",
      "label": "交货日期 ",
      "type": "date",
      "placeholder": "请选择交货日期 ",
      "tabGroup": "mqs_ship_manifest_tab1",
      "tabGroupName": "综合2",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "DELIVERED_TIME",
      "label": "交货时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择交货时间",
      "tabGroup": "mqs_ship_manifest_tab1",
      "tabGroupName": "综合2",
      "showBlock": ["dataInfo"],
      "checked": false
    }, {
      "key": "UPLOADED_WS2PC_ID",
      "label": "Uploaded_Ws2pc_Id",
      "type": "numrange",
      "placeholder": "请选择Uploaded_Ws2pc_Id",
      "tabGroup": "mqs_ship_manifest_tab1",
      "tabGroupName": "综合2",
      "showBlock": [],
      "checked": false
    }, {
      "key": "UPLOADED",
      "label": "已上传",
      "type": "select",
      "placeholder": "请选择是否已上传",
      "tabGroup": "mqs_ship_manifest_tab1",
      "tabGroupName": "综合2",
      "showBlock": ["all"],
      "checked": false,
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "UPLOADED_FILENAME",
      "label": "上传文件名称",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入上传文件名称",
      "tabGroup": "mqs_ship_manifest_tab1",
      "tabGroupName": "综合2",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "UPLOADED_DSTAMP",
      "label": "上传日期",
      "type": "date",
      "placeholder": "请选择上传日期",
      "tabGroup": "mqs_ship_manifest_tab1",
      "tabGroupName": "综合2",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "UPLOADED_TIME",
      "label": "上传时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择上传时间",
      "tabGroup": "mqs_ship_manifest_tab1",
      "tabGroupName": "综合2",
      "showBlock": ["dataInfo"],
      "checked": false
    }, {
      "key": "SPEC_CODE",
      "label": "规格代码",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入规格代码",
      "tabGroup": "mqs_ship_manifest_tab1",
      "tabGroupName": "综合2",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "BATCH_ID",
      "label": "批次",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入批次",
      "tabGroup": "mqs_ship_manifest_tab1",
      "tabGroupName": "综合2",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "EXPIRY_DSTAMP",
      "label": "失效日期 ",
      "type": "date",
      "placeholder": "请选择失效日期 ",
      "tabGroup": "mqs_ship_manifest_tab1",
      "tabGroupName": "综合2",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "PICK_LABEL_ID",
      "label": "拣货标签ID",
      "type": "numrange",
      "placeholder": "请选择拣货标签ID",
      "tabGroup": "mqs_ship_manifest_tab1",
      "tabGroupName": "综合2",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "PROFORMA_INVOICE_NUM",
      "label": "发票号",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入发票号",
      "tabGroup": "mqs_ship_manifest_tab1",
      "tabGroupName": "综合2",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "POD_EXCEPTION_REASON",
      "label": "异常代码",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入异常代码",
      "tabGroup": "mqs_ship_manifest_tab1",
      "tabGroupName": "综合2",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "POD_CONFIRMED",
      "label": "使用发货证明进行确认？",
      "type": "select",
      "placeholder": "请选择使用发货证明进行确认？",
      "tabGroup": "mqs_ship_manifest_tab1",
      "tabGroupName": "综合2",
      "showBlock": ["all"],
      "checked": false,
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "PALLET_CONFIG",
      "label": "托盘类型",
      "type": "select",
      "placeholder": "请选择托盘类型",
      "tabGroup": "mqs_ship_manifest_tab4",
      "tabGroupName": "托盘明细",
      "showBlock": ["all"],
      "checked": false,
      "options": []
    }, {
      "key": "PALLET_HEIGHT",
      "label": "托盘高",
      "type": "numrange",
      "placeholder": "请选择托盘高",
      "tabGroup": "mqs_ship_manifest_tab4",
      "tabGroupName": "托盘明细",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "PALLET_WIDTH",
      "label": "托盘宽",
      "type": "numrange",
      "placeholder": "请选择托盘宽",
      "tabGroup": "mqs_ship_manifest_tab4",
      "tabGroupName": "托盘明细",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "PALLET_DEPTH",
      "label": "托盘长",
      "type": "numrange",
      "placeholder": "请选择托盘长",
      "tabGroup": "mqs_ship_manifest_tab4",
      "tabGroupName": "托盘明细",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "PALLET_VOLUME",
      "label": "托盘体积",
      "type": "numrange",
      "placeholder": "请选择托盘体积",
      "tabGroup": "mqs_ship_manifest_tab4",
      "tabGroupName": "托盘明细",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "PALLET_WEIGHT",
      "label": "托盘重量",
      "type": "numrange",
      "placeholder": "请选择托盘重量",
      "tabGroup": "mqs_ship_manifest_tab4",
      "tabGroupName": "托盘明细",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "PICKED_WEIGHT",
      "label": "拣货重量",
      "type": "numrange",
      "placeholder": "请选择拣货重量",
      "tabGroup": "mqs_ship_manifest_tab4",
      "tabGroupName": "托盘明细",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "PICKED_VOLUME",
      "label": "拣货体积",
      "type": "numrange",
      "placeholder": "请选择拣货体积",
      "tabGroup": "mqs_ship_manifest_tab4",
      "tabGroupName": "托盘明细",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "STATUS",
      "label": "状态 ",
      "type": "select",
      "placeholder": "请选择状态 ",
      "tabGroup": "mqs_ship_manifest_tab5",
      "tabGroupName": "货箱明细",
      "showBlock": ["all"],
      "checked": false,
      "options": SHIPMENT_STATUS,
      "formatter": ({cellValue, row, column}) => {
        let res = SHIPMENT_STATUS.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "CARRIER_CONTAINER_ID",
      "label": "承运商集装箱ID",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入承运商集装箱ID",
      "tabGroup": "mqs_ship_manifest_tab5",
      "tabGroupName": "货箱明细",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "HUB_CONTAINER_ID",
      "label": "Hub承运商托盘ID",
      "type": "magnifier",
      "tableName": 'SHIPMENT_MANIFEST',
      "placeholder": "请输入Hub承运商托盘ID",
      "tabGroup": "mqs_ship_manifest_tab5",
      "tabGroupName": "货箱明细",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "CONTAINER_TYPE",
      "label": "容器类型",
      "type": "select",
      "placeholder": "请选择容器类型",
      "tabGroup": "mqs_ship_manifest_tab5",
      "tabGroupName": "货箱明细",
      "showBlock": ["all"],
      "checked": false,
      "options": async () => {
        let {status, data: {list}, message} = await setSelectPalletList({
          PALLET_CONFIG: {},
          ...defaultPaginationInfo
        });

        if (Number(status) === 200) {
          return list.map(item => {
            return {
              key: item.CONFIG_ID,
              label: item.CONFIG_ID
            }
          })
        }
      },
    }, {
      "key": "CONTAINER_WEIGHT",
      "label": "容器重",
      "type": "numrange",
      "placeholder": "请选择容器重",
      "tabGroup": "mqs_ship_manifest_tab5",
      "tabGroupName": "货箱明细",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "SHIPMENT_REF",
      "label": "装运参考",
      "type": "magnifier",
      "tableName": 'SHIPMENT_MANIFEST',
      "placeholder": "请输入装运参考",
      "tabGroup": "mqs_ship_manifest_tab5",
      "tabGroupName": "货箱明细",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "CONTAINER_HEIGHT",
      "label": "容器高",
      "type": "numrange",
      "placeholder": "请选择容器高",
      "tabGroup": "mqs_ship_manifest_tab5",
      "tabGroupName": "货箱明细",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "CONTAINER_DEPTH",
      "label": "容器长",
      "type": "numrange",
      "placeholder": "请选择容器长",
      "tabGroup": "mqs_ship_manifest_tab5",
      "tabGroupName": "货箱明细",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "CONTAINER_WIDTH",
      "label": "容器宽",
      "type": "numrange",
      "placeholder": "请选择容器宽",
      "tabGroup": "mqs_ship_manifest_tab5",
      "tabGroupName": "货箱明细",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "CONTAINER_N_OF_N",
      "label": "箱子号 ",
      "type": "numrange",
      "placeholder": "请选择箱子号 ",
      "tabGroup": "mqs_ship_manifest_tab5",
      "tabGroupName": "货箱明细",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "PACKING_PALLET_QTY",
      "label": "打包托数",
      "type": "numrange",
      "placeholder": "请选择打包托数",
      "tabGroup": "mqs_ship_manifest_tab5",
      "tabGroupName": "货箱明细",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "PACKING_PALLET_TYPE",
      "label": "打包托盘类型",
      "type": "select",
      "placeholder": "请选择打包托盘类型",
      "tabGroup": "mqs_ship_manifest_tab5",
      "tabGroupName": "货箱明细",
      "showBlock": ["all"],
      "checked": false,
      "options": async () => {
        let {status, data: {list}, message} = await setSelectPalletList({
          PALLET_CONFIG: {},
          ...defaultPaginationInfo
        });

        if (Number(status) === 200) {
          return list.map(item => {
            return {
              key: item.CONFIG_ID,
              label: item.CONFIG_ID
            }
          })
        }
      },
    }, {
      "key": "REPRINT_LABELS",
      "label": "重打印标签",
      "type": "select",
      "placeholder": "请选择重打印标签",
      "tabGroup": "mqs_ship_manifest_tab5",
      "tabGroupName": "货箱明细",
      "showBlock": ["all"],
      "checked": false,
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "PALLET_LABELLED",
      "label": "托盘被贴标签",
      "type": "select",
      "placeholder": "托盘被贴标签",
      "tabGroup": "mqs_ship_manifest_tab5",
      "tabGroupName": "货箱明细",
      "showBlock": ["all"],
      "checked": false,
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "LABELLED",
      "label": "容器被贴标签",
      "type": "select",
      "placeholder": "容器被贴标签",
      "tabGroup": "mqs_ship_manifest_tab5",
      "tabGroupName": "货箱明细",
      "showBlock": ["all"],
      "checked": false,
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "CUSTOMER_SHIPMENT_NUMBER",
      "label": "客户发货单编号 ",
      "type": "magnifier",
      "tableName": 'SHIPMENT_MANIFEST',
      "placeholder": "请输入客户发货单编号 ",
      "tabGroup": "mqs_ship_manifest_tab6",
      "tabGroupName": "Shipment Details",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "SHIPMENT_NUMBER",
      "label": "发运号码",
      "type": "magnifier",
      "tableName": 'SHIPMENT_MANIFEST',
      "placeholder": "请输入发运号码",
      "tabGroup": "mqs_ship_manifest_tab6",
      "tabGroupName": "Shipment Details",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "CUSTOMER_ID",
      "label": "送货客户",
      "type": "magnifier",
      "tableName": 'SHIPMENT_MANIFEST',
      "placeholder": "请输入送货客户",
      "tabGroup": "mqs_ship_manifest_tab6",
      "tabGroupName": "Shipment Details",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "TRAILER_ID",
      "label": "拖车",
      "type": "input",
      "tableName": 'SHIPMENT_MANIFEST',
      "placeholder": "请输入拖车",
      "tabGroup": "mqs_ship_manifest_tab6",
      "tabGroupName": "Shipment Details",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "CARRIER_ID",
      "label": "承运商ID",
      "type": "magnifier",
      "tableName": 'SHIPMENT_MANIFEST',
      "placeholder": "请输入承运商ID",
      "tabGroup": "mqs_ship_manifest_tab6",
      "tabGroupName": "Shipment Details",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "SERVICE_LEVEL",
      "label": "服务级别 ",
      "type": "magnifier",
      "tableName": 'SHIPMENT_MANIFEST',
      "placeholder": "请输入服务级别 ",
      "tabGroup": "mqs_ship_manifest_tab6",
      "tabGroupName": "Shipment Details",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "HUB_CARRIER_ID",
      "label": "Hub承运商",
      "type": "magnifier",
      "tableName": 'SHIPMENT_MANIFEST',
      "placeholder": "请输入Hub承运商",
      "tabGroup": "mqs_ship_manifest_tab6",
      "tabGroupName": "Shipment Details",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "HUB_SERVICE_LEVEL",
      "label": "Hub服务级别",
      "type": "magnifier",
      "tableName": 'SHIPMENT_MANIFEST',
      "placeholder": "请输入Hub服务级别",
      "tabGroup": "mqs_ship_manifest_tab6",
      "tabGroupName": "Shipment Details",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "FROM_LABEL",
      "label": "始发标签",
      "type": "magnifier",
      "tableName": 'SHIPMENT_MANIFEST',
      "placeholder": "请输入始发标签",
      "tabGroup": "mqs_ship_manifest_tab6",
      "tabGroupName": "Shipment Details",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "TO_LABEL",
      "label": "到达标签",
      "type": "magnifier",
      "tableName": 'SHIPMENT_MANIFEST',
      "placeholder": "请输入到达标签",
      "tabGroup": "mqs_ship_manifest_tab6",
      "tabGroupName": "Shipment Details",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "CARRIER_CONSIGNMENT_NUM",
      "label": "承运商托运编号",
      "type": "magnifier",
      "tableName": 'SHIPMENT_MANIFEST',
      "placeholder": "请输入承运商托运编号",
      "tabGroup": "mqs_ship_manifest_tab6",
      "tabGroupName": "Shipment Details",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "CARRIER_CONSIGNMENT_ID",
      "label": "承运商托运单号",
      "type": "magnifier",
      "tableName": 'SHIPMENT_MANIFEST',
      "placeholder": "请输入承运商托运单号",
      "tabGroup": "mqs_ship_manifest_tab6",
      "tabGroupName": "Shipment Details",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "DELIVERED",
      "label": "是否已发货 ",
      "type": "select",
      "placeholder": "请选择是否已发货 ",
      "tabGroup": "mqs_ship_manifest_tab6",
      "tabGroupName": "Shipment Details",
      "showBlock": ["all"],
      "checked": false,
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "PACK_DESPATCH_REPACK",
      "label": "打包和派送",
      "type": "select",
      "placeholder": "请选择打包和派送",
      "tabGroup": "mqs_ship_manifest_tab6",
      "tabGroupName": "Shipment Details",
      "showBlock": ["all"],
      "checked": false,
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "USER_DEF_TYPE_1",
      "label": "用户自定义类型 1 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义类型 1 ",
      "tabGroup": "mqs_ship_manifest_tab30",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_2",
      "label": "用户自定义类型 2 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义类型 2 ",
      "tabGroup": "mqs_ship_manifest_tab30",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_3",
      "label": "用户自定义类型 3 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义类型 3 ",
      "tabGroup": "mqs_ship_manifest_tab30",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_4",
      "label": "用户自定义类型 4 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义类型 4 ",
      "tabGroup": "mqs_ship_manifest_tab30",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_5",
      "label": "用户自定义类型 5 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义类型 5 ",
      "tabGroup": "mqs_ship_manifest_tab30",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_6",
      "label": "用户自定义类型 6 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义类型 6 ",
      "tabGroup": "mqs_ship_manifest_tab30",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_7",
      "label": "用户自定义类型 7 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义类型 7 ",
      "tabGroup": "mqs_ship_manifest_tab30",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_8",
      "label": "用户自定义类型 8 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义类型 8 ",
      "tabGroup": "mqs_ship_manifest_tab30",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_CHK_1",
      "label": "用户自定义的检查1 ",
      "type": "select",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义的检查1 ",
      "tabGroup": "mqs_ship_manifest_tab30",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "USER_DEF_CHK_2",
      "label": "用户自定义的检查2 ",
      "type": "select",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义的检查2 ",
      "tabGroup": "mqs_ship_manifest_tab30",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "USER_DEF_CHK_3",
      "label": "用户自定义的检查3 ",
      "type": "select",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义的检查3 ",
      "tabGroup": "mqs_ship_manifest_tab30",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "USER_DEF_CHK_4",
      "label": "用户自定义的检查4 ",
      "type": "select",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义的检查4 ",
      "tabGroup": "mqs_ship_manifest_tab30",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "USER_DEF_DATE_1",
      "label": "用户自定义日期 1 ",
      "type": "date",
      "placeholder": "请选择用户自定义日期 1 ",
      "tabGroup": "mqs_ship_manifest_tab30",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TIME_1",
      "label": "用户自定义时间 1 ",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择用户自定义时间 1 ",
      "tabGroup": "mqs_ship_manifest_tab30",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_DATE_2",
      "label": "用户自定义日期 2 ",
      "type": "date",
      "placeholder": "请选择用户自定义日期 2 ",
      "tabGroup": "mqs_ship_manifest_tab30",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TIME_2",
      "label": "用户自定义时间 2 ",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择用户自定义时间 2",
      "tabGroup": "mqs_ship_manifest_tab30",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_DATE_3",
      "label": "用户自定义日期 3 ",
      "type": "date",
      "placeholder": "请选择用户自定义日期 3 ",
      "tabGroup": "mqs_ship_manifest_tab30",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TIME_3",
      "label": "用户自定义时间 3",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择用户自定义时间 3",
      "tabGroup": "mqs_ship_manifest_tab30",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_DATE_4",
      "label": "用户自定义日期 4 ",
      "type": "date",
      "placeholder": "请选择用户自定义日期 4 ",
      "tabGroup": "mqs_ship_manifest_tab30",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TIME_4",
      "label": "用户自定义时间 4",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择用户自定义时间 4",
      "tabGroup": "mqs_ship_manifest_tab30",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_NUM_1",
      "label": "用户自定义号码 1 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义号码 1 ",
      "tabGroup": "mqs_ship_manifest_tab30",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_NUM_2",
      "label": "用户自定义号码 2",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义号码 2",
      "tabGroup": "mqs_ship_manifest_tab30",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_NUM_3",
      "label": "用户自定义号码 3 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义号码 3 ",
      "tabGroup": "mqs_ship_manifest_tab30",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_NUM_4",
      "label": "用户自定义号码 4 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义号码 4 ",
      "tabGroup": "mqs_ship_manifest_tab30",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_NOTE_1",
      "label": "用户自定义注释 1 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义注释 1 ",
      "tabGroup": "mqs_ship_manifest_tab30",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_NOTE_2",
      "label": "用户自定义注释 2 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义注释 2 ",
      "tabGroup": "mqs_ship_manifest_tab30",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    },{
      "key": "ARCHIVED",
      "label": "是否存档",
      "type": "select",
      "placeholder": "请选择是否存档",
      "showBlock": [],
      "checked": false,
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "BOL_ID",
      "label": "提货单",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入提货单",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CARRIER_MANIFEST_NUMBER",
      "label": "承运商出库单号",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入承运商出库单号",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CE_CONSIGNMENT_ID",
      "label": "",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CE_COO",
      "label": "",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CE_CWC",
      "label": "",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CE_DOCUMENT_DSTAMP",
      "label": "",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CE_DUTY_STAMP",
      "label": "",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CE_INSTRUCTIONS",
      "label": "",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CE_ORDER_ID",
      "label": "",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CE_ORIGINATOR",
      "label": "",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CE_ORIGINATOR_REFERENCE",
      "label": "",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CE_REASON_NOTES",
      "label": "",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CE_RECEIPT_TYPE",
      "label": "",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CE_ROTATION_ID",
      "label": "",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CE_STATUS",
      "label": "",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CE_UCR",
      "label": "",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CE_UNDER_BOND",
      "label": "",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CONDITION_ID",
      "label": "条件代码 ",
      "type": "magnifier",
      "placeholder": "请选择条件代码 ",
      "showBlock": [],
      "checked": false,
      // "options": async () => {
      //   let {status, data, message} = await setSelectConditionList({
      //     ...defaultPaginationInfo
      //   });
      //
      //   if (Number(status) === 200) {
      //     return data.map(item=>{
      //       return{
      //         key:item.CONDITION_ID,
      //         label:item.NOTES
      //       }
      //     })
      //   }
      // },
    }, {
      "key": "KEY",
      "label": "",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "KIT_RATIO",
      "label": "组装率",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入组装率",
      "showBlock": [],
      "checked": false
    }, {
      "key": "LOCK_CODE",
      "label": "锁定代码 ",
      "type": "select",
      "placeholder": "请选择锁定代码 ",
      "showBlock": [],
      "checked": false,
      "options": async () => {
        let {status, data, message} = await setSelectInventoryLockCodeList({
          ...defaultPaginationInfo
        });

        if (Number(status) === 200) {
          return data.map(item => {
            return {
              key: item.CONDITION_ID,
              label: item.NOTES
            }
          })
        }
      },
    }, {
      "key": "LOCK_STATUS",
      "label": "锁定状态",
      "type": "select",
      "placeholder": "请选择锁定状态",
      "showBlock": [],
      "checked": false,
      "options": SHIPMENT_LOCK_STATUS,
      "formatter": ({cellValue, row, column}) => {
        let res = SHIPMENT_LOCK_STATUS.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "NOTES",
      "label": "备注",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入备注",
      "showBlock": [],
      "checked": false
    }, {
      "key": "ORIGIN_ID",
      "label": "产地",
      "type": "select",
      "placeholder": "请选择产地",
      "showBlock": [],
      "checked": false,
      "options": async () => {
        let {status, data, message} = await setSelectOriginList({
          ...defaultPaginationInfo
        });

        if (Number(status) === 200) {
          return data.map(item => {
            return {
              key: item.ORIGIN_ID,
              label: item.NOTES
            }
          })
        }
      },
    }, {
      "key": "RECEIPT_DSTAMP",
      "label": "收货日期",
      "type": "date",
      "placeholder": "请选择收货日期",
      "showBlock": [],
      "checked": false
    }, {
      "key": "RECEIPT_ID",
      "label": "收货单号",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入收货单号",
      "showBlock": [],
      "checked": false
    }, {
      "key": "SAP_PICK_ID",
      "label": "Sap 捡货标识 ",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入Sap 捡货标识 ",
      "showBlock": [],
      "checked": false
    }, {
      "key": "SAP_PICK_REQ",
      "label": "Sap 拣货需求 ",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入Sap 拣货需求 ",
      "showBlock": [],
      "checked": false
    }, {
      "key": "SAP_TID",
      "label": "Sap tid ",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入Sap tid ",
      "showBlock": [],
      "checked": false
    }, {
      "key": "SITE_TRANSFERRED",
      "label": "是否调拨",
      "type": "select",
      "placeholder": "请选择是否调拨",
      "showBlock": [],
      "checked": false,
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "SUPPLIER_ID",
      "label": "供应商",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入供应商",
      "showBlock": [],
      "checked": false
    }, {
      "key": "TOTAL_VOLUME",
      "label": "总体积",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入总体积",
      "showBlock": [],
      "checked": false
    }, {
      "key": "TRACKING_LEVEL",
      "label": "单位",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入单位",
      "showBlock": [],
      "checked": false
    }, {
      "key": "TRANSPORT_BOXES",
      "label": "运输箱数",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入运输箱数",
      "showBlock": [],
      "checked": false
    }, {
      "key": "TROLLEY_SLOT_ID",
      "label": "小车拣选格号",
      "type": "autoComplete",
      "tableName": "SHIPMENT_MANIFEST",
      "placeholder": "请输入小车拣选格号",
      "showBlock": [],
      "checked": false
    }, {
      "key": "UPLOADED_CUSTOMS",
      "label": "上传到海关系统",
      "type": "select",
      "placeholder": "请选择上传到海关系统",
      "showBlock": [],
      "checked": false,
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "UPLOADED_VVIEW",
      "label": "上传到VirtualView ",
      "type": "select",
      "placeholder": "请选择上传到VirtualView ",
      "showBlock": [],
      "checked": false,
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    },
      {
        "key": "ORDER_ID",
        "label": "订单号",
        "type": "magnifier",
        "tableName": 'SHIPMENT_MANIFEST',
        "placeholder": "请输入订单号",
        "tabGroup": "THead",
        "tabGroupName": "",
        "showBlock": ["dataInfo"],
        "checked": false
      }, {
        "key": "CLIENT_ID",
        "label": "客户端",
        "type": "magnifier",
        "placeholder": "请选择客户端",
        "tabGroup": "THead",
        "tabGroupName": "",
        "showBlock": ["dataInfo"],
        defaultUpper:true,
        "checked": false,
      }, {
        "key": "OWNER_ID",
        "label": "货主",
        "type": "magnifier",
        "placeholder": "请选择货主",
        "tabGroup": "THead",
        "tabGroupName": "",
        "showBlock": ["dataInfo"],
        "checked": false,
      }, {
        "key": "SKU_ID",
        "label": "SKU ",
        "type": "magnifier",
        "placeholder": "请输入SKU ",
        "tabGroup": "THead",
        "tabGroupName": "",
        "showBlock": ["dataInfo"],
        "checked": false
      }, {
        "key": "TAG_ID",
        "label": "Tag标识 ",
        "type": "magnifier",
        "tableName": 'SHIPMENT_MANIFEST',
        "placeholder": "请输入Tag标识 ",
        "tabGroup": "THead",
        "tabGroupName": "",
        "showBlock": ["dataInfo"],
        "checked": false
      }, {
        "key": "SITE_ID",
        "label": "站点",
        "type": "select",
        "placeholder": "请选择站点",
        "tabGroup": "THead",
        "tabGroupName": "",
        "showBlock": ["dataInfo"],
        "checked": false,
        "options": async () => {
          let {status, data: {list}, message} = await setSelectSiteList({
            SITE: {},
            ...defaultPaginationInfo
          });

          if (Number(status) === 200) {
            return list.map(item => {
              return {
                key: item.SITE_ID,
                label: item.SITE_ID
              }
            })
          }
        },
      },]
  }
}