<template>
  <div class="common">
    <com-form
      ref="common"
      label-width="120px"
      label-position="right"
      :col="3"
      :configs="filterFormConfig"
      showType="info"
    ></com-form>
  </div>
</template>

<script>
import { formConfig } from "../config/formConfig.js"

export default {
  name: 'common',
  data() {
    const config = formConfig(this);

    return {
      formData: {},
      filterFormConfig:config.commmonFormConfig.length ? config.commmonFormConfig[0].tagConfigs : []
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped></style>