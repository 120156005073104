import Api from '@api';

import common from './blocks/common'
import tag from './blocks/tag'

import { formConfig } from './config/formConfig'

export default {
  name: 'shippingDetail',
  components: {
    common,
    tag
  },
  data() {
    const thead = formConfig(this).thead;
    return {
      commonData: {},
      thead,
    };
  },
  computed:{
    injectData(){
      return this.$route.query || {}
    }
  },
  activated() {
    this.init();
  },
  mounted() {
    this.init();
  },
  methods: {
    init(){
      this.getData();
    },
    async getData() {
      let { status, data } = await Api.GetShippingManifestInfo({
        KEY: this.injectData.KEY
      });
      if (Number(status) === 200) {
        let result = JSON.parse(JSON.stringify(data));
        this.$refs.common.$refs.common.formData = result;
        this.thead
          .filter(item => item !== "THead")
          .forEach(item => {
            this.$refs.tag.$refs[item][0].formData = result
          })
      }
    },
    handleConfirm() {
      this.$emit('close')
    },
    handleClose() {
      this.$emit('close')
    }
  }
}